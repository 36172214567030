<template>
    <div>
        <br>
        <h3 class="text-dark">Configuration</h3>
        <br>
        <div>
            <div class="box">
                <h4>Build Database</h4>
                <p>You are currently running the latest database build version (1.1.2).</p>
                <div>
                    <b-button squared size="sm" @click="$store.dispatch('system/build')">Build</b-button>
                </div>
                <br>
                <b-textarea class="rounded-0" rows="6"></b-textarea>
            </div>

            <br>

            <div class="box">
                <h4>Upgrade Modules</h4>
                <p>You are currently running the latest database build version (1.1.2).</p>
                <div>
                    <b-button squared size="sm" @click="$store.dispatch('system/upgrade')">Upgrade</b-button>
                </div>
                <br>
                <b-textarea class="rounded-0" rows="6"></b-textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hasLoaded: false,
            error: null,
            table: {
                fields: [
                    {
                        key: 'name',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'module_id',
                        sortable: true,
                        label: 'Module',
                        filter: 'like'
                    },
                    {
                        key: 'value',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'activities',
                        sortable: false,
                        label: '',
                    },
                ]
            },
        };
    },
    methods: {
        loadRules(filter) {
            this.$store.dispatch("system/rule/get", { params: filter })
        }
    },
    created() {
        this.loadRules()
    },
};
</script>

<style lang="scss">
.box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 0.75rem 1.25rem;
    background: white;
}
</style>